.App {
  text-align: center;
}

i {
  vertical-align: middle;
}

::-webkit-scrollbar {
  width: 5px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}

// tabs header centering in contact upload
.tab-header-center .ant-tabs-nav,
.tab-header-center .ant-tabs-nav > div:first-child {
  width: 100% !important;
}

.tab-header-center .ant-tabs-nav > div {
  width: 48% !important;
}

.tab-header-center .ant-tabs-tab {
  width: 48% !important;
}

// import contacts icon

.import-icon {
  cursor: pointer;
  background-color: #fff;
  border: 1px solid #7fd1de;
  width: 300px;
  transition: box-shadow 0.4s;
  text-align: center;
  margin: 20px auto;
  border-radius: 0.375rem;
}

.import-icon p {
  font-size: 1.25rem;
  margin-top: 1rem;
  font-weight: 700;
  text-align: center;
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.import-icon:hover {
  box-shadow: 0 0 0 1px #7fd1de, 0 0 12px 0 rgba(0, 163, 189, 0.3);
}

@media (min-width: 768px) {
  .import-icon {
    padding: 3rem;
  }
}

.layout {
  min-height: 100vh !important;
}

.fadeInRight {
  animation: fadeInRight 0.3s ease-in-out;
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translateX(300px);
  }

  to {
    opacity: 1;
  }
}

// helper classes

.font-size-10 {
  font-size: 10px !important;
}

.font-size-11 {
  font-size: 11px !important;
}

.font-size-12 {
  font-size: 12px !important;
}

.font-size-13 {
  font-size: 13px !important;
}

.font-size-14 {
  font-size: 14px !important;
}

.font-size-15 {
  font-size: 15px !important;
}

.font-size-16 {
  font-size: 16px !important;
}

.font-size-17 {
  font-size: 17px !important;
}

.font-size-18 {
  font-size: 18px !important;
}

.font-size-20 {
  font-size: 20px !important;
}

.font-size-22 {
  font-size: 22px !important;
}

.font-size-24 {
  font-size: 24px !important;
}

// soft badges

.badge-soft-success {
  background-color: rgba(52, 195, 143, 0.18) !important;
  color: #34c38f;
  border-radius: 50rem;
  padding: 0.15em 0.5em;
  font-size: 11px;
}

.badge-soft-info {
  background-color: rgba(24, 151, 183, 0.18) !important;
  color: #149cbe;
  border-radius: 50rem;
  padding: 0.15em 0.5em;
  font-size: 11px;
}

.badge-soft-danger {
  background-color: #f46a6a2e !important;
  color: #f46a6a;
  border-radius: 50rem;
  padding: 0.15em 0.5em;
  font-size: 11px;
}

@keyframes tada {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  10%,
  20% {
    -webkit-transform: scale3d(0.95, 0.95, 0.95) rotate3d(0, 0, 1, -10deg);
    transform: scale3d(0.95, 0.95, 0.95) rotate3d(0, 0, 1, -10deg);
  }

  30%,
  50%,
  70%,
  90% {
    -webkit-transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, 10deg);
    transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, 10deg);
  }

  40%,
  60%,
  80% {
    -webkit-transform: rotate3d(0, 0, 1, -10deg);
    transform: rotate3d(0, 0, 1, -10deg);
  }

  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

.bell-tada {
  -webkit-animation: tada 1.5s ease infinite;
  animation: tada 1.5s ease infinite;
}
