.modalDes {
  color: var(--secondary-font-color) !important;
}

.modalCloseBtn {
  color: var(--white-color);
  position: absolute;
  top: 10px;
  right: 10px;
  width: 25px;
  padding: 0;
  height: 25px;
  line-height: 27px;
  font-size: 14px;
  border: none;
  border-radius: 50%;
  background: #00000050;
}

.modalCloseBtn > i {
  vertical-align: initial;
}

.modalCloseBtn:hover {
  color: var(--white-color);
  background: #00000070;
}

.searchBarCover {
  position: relative;
  height: 100%;
}

.searchBarCover .searchIcon {
  width: auto;
  position: absolute;
  padding: 1em 0 1em 2em;
}

.searchBarSmall {
  padding: 0 2rem;
  margin-right: 1rem;
  background: #11b6ca10;
  border-radius: 5px;
  border: none;
  min-height: 42px;
  margin-left: 1rem;
}

.btnMain button {
  width: initial;
  background-color: var(--main-color);
  border: none;
}

.btnMain button:hover {
  background-color: var(--main-color);
  border: none;
}

.btnWhite {
  width: initial;
  background: transparent;
  color: var(--main-color);
  border: 1px solid var(--main-color);
}

.btnWhite:hover {
  background: transparent;
  color: var(--main-color);
  border: 1px solid var(--main-color);
}

.quickContact ul li:hover {
  background: var(--white-color);
}

.inputBarSmall {
  border: 1px solid var(--main-color);
  border-radius: 4px;
  padding: 10px 20px;
  width: 100%;
  background: transparent;
  /* color: ; */
}

.form-label {
  font-size: 1.2em;
  font-weight: 600;
}

.coverCardsSmall {
  max-height: 445px;
  overflow-y: auto;
  width: inherit;
}

.cardGroup {
  background-color: #68686810;
  display: grid !important;
  grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
  grid-gap: 1rem;
}

.cardGroupLarge {
  background-color: #68686810;
  display: grid !important;
  grid-template-columns: repeat(auto-fit, minmax(600px, 1fr));
  grid-gap: 1rem;
}

.cardGroupSmall {
  background-color: transparent;
  width: 560px;
}

.ellipsis {
  position: absolute;
  right: 3%;
  top: -4%;
  color: var(--main-color);
}

.card .dropdown-toggle {
  border: none;
  content: "\22EF";
  font-size: 1em;
  color: var(--main-color);
}

.card .dropdown-toggle:hover {
  border: none;
  content: "\22EF";
  font-size: 1em;
  color: var(--main-color);
}

.card .dropdown-toggle::after {
  display: none;
}

.card .dropdownMenu small:hover {
  color: var(--main-color);
}

.card-body {
  box-shadow: 0px 10px 30px 10px #00000020;
  height: 12.5rem;
}

.card-body small {
  padding: 0 0 8px 0;
  font-size: 0.75em;
}

.card-group .card-body {
  box-shadow: none;
  height: auto;
}

.cardTitle {
  font-size: 1em !important;
  font-weight: 600;
}

.card-body .cardFooterTemplate {
  padding-top: 0rem !important;
  padding-bottom: 0.5rem !important;
}

.templateCategory small {
  font-size: 0.8em;
  color: #21232270;
  padding: 0;
}

.templateCategory small span {
  color: var(--main-font-color);
}

.cardImg {
  width: 100px !important;
}

.card-group .card:hover {
  box-shadow: 0 0 1rem #00000030 !important;
}

.dropdown-toggle {
  background: transparent;
  color: var(--secondary-font-color);
  padding: 0.2rem;
  border: 1px solid var(--secondary-font-color);
}

.dropdown-toggle:hover {
  background: transparent;
  color: var(--secondary-font-color);
  border: 1px solid var(--secondary-font-color);
}

.dropdownMenu label {
  position: absolute;
  top: 8%;
  left: 20%;
  font-weight: 300;
  font-size: 0.75em;
}

.dropdownMenu .form-radio {
  position: relative;
}

.scheduleDate {
  background: #158bbb15;
}

.scheduleDate p {
  color: var(--second-bg-color);
  margin: 5px;
  font-size: 1.4em;
  font-weight: 600;
}

.scheduleTime {
  overflow-y: scroll;
  height: 260px;
}

.time .selected {
  color: var(--main-color);
  font-weight: 600;
}

.time .form-label {
  font-size: 1.5em;
}

.search-results {
  display: flex;
  max-height: 11rem;
  overflow-y: scroll;
  flex-flow: column;
}

.search-bar-card {
  border: none;
}

.searchBarBig {
  width: 100%;
  margin: 3em 0 0 0;
}

.searchBarBig i {
  position: absolute;
  z-index: 1;
}

.smsCampaign .searchBarBig i {
  position: relative !important;
}

.searchIcon {
  padding: 25px;
  color: #00000050;
  min-width: 40px;
}

.searchBarBig .fa-chevron-down {
  position: absolute;
  right: 4rem;
}

.searchBarBig .fa-chevron-up {
  position: absolute;
  right: 4rem;
}

.searchBarBig-input {
  border-radius: 5px;
  border: 1px solid var(--main-color);
  width: 100%;
  padding: 1rem 3rem;
}

.ant-select-selector {
  border-radius: 5px !important;
  padding: 1em 4em !important;
  width: 100% !important;
  height: 100% !important;
  border: transparent !important;
}

.ant-select-selector span input {
  height: 100% !important;
  padding: 0 3.2em !important;
}

.ant-dropdown-menu {
  padding: 0px 0px !important;
}

.submenu-of {
  font-size: 0.8rem;
  height: 135px;
  overflow: auto;
}

/* Mobile */

.quickSmsView {
  background: transparent;
  border-left: 2px solid #70707010;
}

.quickSmsView img {
  width: 75%;
}

.smsPreview {
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  font-size: 10px;
}

.smsPreview hr {
  color: #455a6430;
  height: 1.5px;
  border: none;
}

.smsPreview .textCover {
  background-color: #11b6ca10;
  margin-bottom: 0.5rem !important;
  width: fit-content;
  clear: both;
}

.smsPreview .textCover:nth-child(even) {
  margin-bottom: 0.5rem !important;
}

.smsPreview .textCover .right {
  margin-right: 1rem;
}

.smsPreview .sendText {
  float: right;
}

.smsPreview p {
  margin: 0;
}

.smsPreview p a {
  text-decoration: none;
}

.smsPreview .smsChat {
  width: 200px;
  height: 355px;
  overflow-y: scroll;
  overflow-x: hidden;
}

/* add template */
.templateBtn {
  font-size: 0.8em;
  padding: 0;
  background: var(--white-color);
  color: var(--main-color);
  border: 1px solid var(--main-color);
  height: 30px;
}

.coverCards {
  max-height: 68vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.templatePreview {
  position: fixed;
  top: 15%;
  background-color: transparent;
  z-index: 1;
  right: 100px;
  height: auto;
  filter: drop-shadow(2px 4px 6px black);
}

.templatePreview img {
  height: 450px;
  width: auto;
}

.templatePreview .smsPreview {
  position: absolute;
  top: 11%;
  left: 42px;
  width: 200px;
  height: 400px;
}

.templatesOverlayBtn {
  z-index: 1000;
  width: 30px;
  height: 30px;
  position: absolute;
  right: -29px;
  top: -15px;
  line-height: 6px;
  border-radius: 50%;
  border: none;
  font-size: 1rem;
  background-color: #00000060;
  color: var(--white-color);
}

.hidden {
  display: none !important;
}

.createGroup .tableResponsive {
  height: 17rem;
  padding: 0 10px 10px 0;
}

.createGroup .tableResponsive table {
  width: max-content;
  height: 17rem;
}

.createGroup table {
  border-collapse: separate !important;
  border-spacing: 0 0.5rem !important;
}

.createGroup table thead tr th {
  padding: 0px 1rem;
  height: 2.5rem;
  font-size: 1.1em;
  width: 9rem;
  vertical-align: middle;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1;
  background: var(--white-color);
}

.createGroup table thead tr th:nth-child(1) {
  width: auto;
}

.createGroup table tbody tr {
  background-color: #11b6ca10;
  height: 2.5rem;
  padding: 10px;
}

.createGroup table tbody tr td {
  border-color: var(--white-color) !important;
  border-width: 0px !important;
}

.createGroup table tbody tr td {
  padding: 0px 1rem;
  height: 2.5rem;
  width: 9rem;
  vertical-align: middle;
  top: 0;
  z-index: 1;
}

.createGroup table tbody tr td:first-child {
  width: auto;
  padding: 0 1rem !important;
  border-top-left-radius: 10px;
}

.createGroup table tbody tr td:first-child {
  border-bottom-left-radius: 10px;
}

.createGroup table tbody tr td:last-child {
  border-top-right-radius: 10px;
}

.createGroup table tbody tr td:last-child {
  border-bottom-right-radius: 10px;
}

.createGroup .dropdownMenu label:hover {
  color: var(--main-color);
}

.createGroup thead tr th .primeInput {
  margin: 13px 0;
  border-radius: 50%;
  border: 3px solid var(--appMainDarkColor);
  height: 20px;
  width: 20px;
}

.createGroup thead tr th .primeinput:checked {
  background-color: var(--appMainDarkColor);
  content: "";
  color: var(--white-color);
}

.createGroup tbody tr td .primeinput:checked {
  background-color: var(--appMainDarkColor);
  content: "";
  color: var(--white-color);
}

.createGroup tbody tr td .primeInput {
  margin: 13px 0;
  border-radius: 50%;
  border: 3px solid var(--appMainDarkColor);
  height: 20px;
  width: 20px;
}

.popupImg {
  position: absolute;
  top: -12%;
  width: 60px;
  left: 50%;
  transform: translateX(-50%);
}

.formLabelBig {
  font-weight: 600;
  color: var(--black-color);
  font-size: 1.3em;
}

.mobileView {
  position: relative;
}

.mobileView .quickSmsView {
  padding: 0 !important;
  width: 15rem;
  border-left: none;
}

.mobileView .quickSmsView img {
  width: 100%;
}

.mobileView .smsPreview {
  position: absolute;
  top: 6%;
  left: 50%;
  transform: translateX(-50%);
  right: 0;
  width: 14rem;
  height: 400px;
}

.mobileView .smsPreview .smsChat {
  width: 97%;
}

.mobileView .smsPreview p {
  font-size: 1em;
}

.createBar {
  padding: 0 2rem;
  margin-right: 1rem;
  background: #70707010;
  border-radius: 5px;
  border: none;
  min-height: 42px;
  margin-left: 1rem;
}

.title span {
  font-size: 1.2em;
  color: var(--main-color);
}

.removeUnicodeTextarea {
  background: transparent;
  border: none;
  width: 100%;
  white-space: pre-line;
  resize: none;
  height: calc(100vh - 500px);
  overflow: scroll;
}

.removeUnicodeTextarea:focus {
  outline: none !important;
}

.characterTextarea {
  background: transparent;
  border: none;
  width: 100%;
  white-space: pre-line;
  resize: none;
  height: calc(100vh - 350px);
  overflow: scroll;
}

.characterTextarea:focus {
  outline: none !important;
}

.removeUnicodePreview {
  background: transparent;
  border: none;
  width: 100%;
  resize: none;
  height: calc(100vh - 500px);
  overflow: scroll;
}

.addOption {
  cursor: pointer;
}

.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 10;
}

.selectCard {
  background-color: #11b6ca10;
}

.eyePreview {
  color: var(--main-color);
}

.eyePreview:hover,
.eyePreview:focus {
  color: #097e8b;
}

.hiddenOption {
  height: 0px;
  overflow: hidden;
  transition: height 0.3s;
}

.inputForm.hiddenOption {
  transition: height 0.3s;
  height: 55px;
}
