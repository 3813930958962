.label {
  color: var(--main-color);
  font-weight: 600;
}

.hidden {
  height: 0px;
  overflow: hidden;
  transition: height 0.2s;
}

.inputForm.hidden {
  transition: height 0.2s;
  height: 44px;
  padding: 2px;
}
