/* Search Bar */
.inputBar {
  border: none;
  background-color: var(--appMainLightColor);
  font-size: 15px;
}

.inputIcon {
  background-color: var(--appMainLightColor);
  font-size: 15px;
  border: none;
  color: #33333390;
}

/* Character PopUp */

.popup {
  position: absolute;
  bottom: 32%;
  right: 01%;
  z-index: 1;
  width: fit-content;
}

.popupImg {
  position: absolute;
  top: -27%;
  left: 50%;
  transform: translateX(-50%);
}

.popupContent {
  background-color: var(--white-color);
  width: fit-content;
  padding: 12px 25px 0px;
  border-radius: 50px;
  border: 1px solid red;
}

.popupContent hr {
  padding: 0;
  margin: 0;
}

/* date picker */
.antPickerInput input {
  border-radius: 10px !important;
}
