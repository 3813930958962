.sidebar {
  min-height: 80vh;
  border-top-left-radius: 10px;
  border-top-right-radius: 50px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 0;
  background-color: #149cbe10;
}

.sidebarHeading {
  color: var(--main-font-color);
  font-size: 1.5em;
  font-weight: 700;
}

.profile {
  border: 2px solid var(--main-color);
  border-radius: 50%;
  width: 65px;
  height: 65px;
}

.group {
  width: 18rem;
}

.mainIcon {
  width: 50px;
  height: 50px;
  text-align: center;
  background-color: #ffffff30;
  font-size: 25px;
  line-height: 50px;
  border-radius: 8px;
  color: #00000025;
}

.mainItem {
  color: #21232250;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
}

.hoverIcon {
  color: var(--main-color);
  background-color: var(--white-color);
}

.hoverText {
  color: var(--black-color);
}

.activeIcon {
  color: var(--main-color);
  background-color: var(--white-color);
}

.activeIcon::after {
  content: "|";
  position: absolute;
  height: 40px;
  width: 4px;
  background-color: var(--main-color);
  border-radius: 8px;
  top: 5px;
  left: -18px;
  z-index: 1;
}

.mainItem.active {
  background-color: var(--bg-color);
  color: var(--main-color);
  width: 13rem;
  position: relative;
  border-radius: 33px 0 0 33px !important;
}

.mainItem.active::before {
  content: "";
  position: absolute;
  background-color: transparent;
  bottom: -55px;
  right: 0px;
  height: 55px;
  width: 27px;
  border-top-right-radius: 33px;
  box-shadow: 0 -27px 0 0 var(--bg-color);
}

.mainItem.active::after {
  content: "";
  position: absolute;
  background-color: transparent;
  top: -55px;
  right: 0px;
  height: 55px;
  width: 27px;
  border-bottom-right-radius: 33px;
  box-shadow: 0 27px 0 0 var(--bg-color);
}

// Loader Animation

@-webkit-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}

.text,
.image,
.icon,
.label {
  -webkit-animation-duration: 1.25s;
  animation-duration: 1.25s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: placeHolderShimmer;
  animation-name: placeHolderShimmer;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  background: rgba(17, 182, 202, 0.5); //#f6f6f6
  background: linear-gradient(to right, rgba(17, 182, 202, 0.5) 8%, rgba(17, 182, 202, 0.6) 18%, rgba(17, 182, 202, 0.5) 33%); // #f6f6f6 #f0f0f0 #f6f6f6
  background-size: 800px 104px;
  height: 96px;
  position: relative;
}

.image {
  width: 65px;
  height: 65px;
  border-radius: 50%;
}

.icon {
  width: 50px;
  height: 50px;
  border-radius: 8px;
}

.text {
  width: 130px;
  height: 35px;
}

.label {
  width: 190px;
  height: 35px;
}
