@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");
@import url("https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css");

:root {
  --gray1: #a8a8a8;
  --gray2: #f4f4f4;
  --white: #fff;
  --colorprimay: rgb(29, 174, 130);
  --colorprimaylight: rgb(13, 153, 106);
  --greenneutral: #2e71b8;
  --backdroundlite: #e8f3ff;
  --gray1: #a8a8a8;
  --gray2: #f4f4f4;
  --dataTableHead: #588cbf;
  --hoverActive: #eee;
  --logoutBackground: url("%PUBLIC_URL%/images/logoutbackgroundnew.png");
  --guniGreen: #3cc99e;
  --guniGreenDark: #31b089;
}

body {
  font-family: "Roboto", sans-serif !important;
}

* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  outline: none !important;
}

.page-content {
  max-height: calc(100vh - 100px);
  overflow: auto;
}

.page-title {
  margin-bottom: 0;
}

.btn-outline-light {
  color: #f8f9fa !important;
}

.btn-outline-light:hover {
  color: var(--colorprimaylight) !important;
}

.btn-light {
  color: var(--colorprimaylight) !important;
}

a,
button {
  -webkit-transition: all 0.1s ease;
  transition: all 0.1s ease;
}

a:hover,
a:focus,
a:active,
a:visited {
  color: var(--colorprimay);
  text-decoration: none !important;
}

.btn-round {
  border: none;
  background: var(--colorprimaylight);
  color: #fff;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  font-size: 25px;
}

.text-green {
  color: #1a1a1a;
}

/* bundle ribbon */
.bundle-ribbon {
  --f: 14px;
  /* control the folded part*/
  --r: 10px;
  /* control the ribbon shape */
  --t: 2px;
  /* the top offset */

  position: absolute;
  inset: var(--t) calc(-1 * var(--f)) auto auto;
  padding: 0 10px var(--f) calc(10px + var(--r));
  clip-path: polygon(0 0, 100% 0, 100% calc(100% - var(--f)), calc(100% - var(--f)) 100%, calc(100% - var(--f)) calc(100% - var(--f)), 0 calc(100% - var(--f)), var(--r) calc(50% - var(--f) / 2));
  color: white;
  box-shadow: 0 calc(-1 * var(--f)) 0 inset #0005;
  opacity: 1 !important;
}

.styled-input-group,
.contact-group-page .group-search-box,
.page-chat .chat-sidebar .choose-device,
.page-chat .chat-sidebar .search-contact,
.bulk-sms-page .group-search-box,
.scheduler-page .group-search-box,
.template-page .group-search-box {
  background: #ebebeb;
  padding: 12px 12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  margin-bottom: 10px;
}

.styled-input-group .dropdwn-option a.toggle-button,
.contact-group-page .group-search-box .dropdwn-option a.toggle-button,
.page-chat .chat-sidebar .choose-device .dropdwn-option a.toggle-button,
.page-chat .chat-sidebar .search-contact .dropdwn-option a.toggle-button,
.bulk-sms-page .group-search-box .dropdwn-option a.toggle-button,
.scheduler-page .group-search-box .dropdwn-option a.toggle-button,
.template-page .group-search-box .dropdwn-option a.toggle-button {
  color: #fff;
  background: var(--colorprimay);
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  line-height: 72px;
  padding: 0px 17px;
}

.styled-input-group form,
.contact-group-page .group-search-box form,
.page-chat .chat-sidebar .choose-device form,
.page-chat .chat-sidebar .search-contact form,
.bulk-sms-page .group-search-box form,
.scheduler-page .group-search-box form,
.template-page .group-search-box form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding-right: 0px;
}

.styled-input-group form .input-wrap,
.contact-group-page .group-search-box form .input-wrap,
.page-chat .chat-sidebar .choose-device form .input-wrap,
.page-chat .chat-sidebar .search-contact form .input-wrap,
.bulk-sms-page .group-search-box form .input-wrap,
.scheduler-page .group-search-box form .input-wrap,
.template-page .group-search-box form .input-wrap {
  border-radius: 20px;
  overflow: hidden;
  width: calc(100% - 42px);
  margin-right: 7px;
  position: relative;
}

.styled-input-group form .input-wrap:before,
.contact-group-page .group-search-box form .input-wrap:before,
.page-chat .chat-sidebar .choose-device form .input-wrap:before,
.page-chat .chat-sidebar .search-contact form .input-wrap:before,
.bulk-sms-page .group-search-box form .input-wrap:before,
.scheduler-page .group-search-box form .input-wrap:before,
.template-page .group-search-box form .input-wrap:before {
  content: "\eb7b";
  font-family: "LineIcons";
  position: absolute;
  z-index: 1;
  top: 10px;
  right: 13px;
  color: #acaaaa;
  pointer-events: none;
  z-index: 1;
}

.styled-input-group form .input-wrap input[type="text"],
.contact-group-page .group-search-box form .input-wrap input[type="text"],
.page-chat .chat-sidebar .choose-device form .input-wrap input[type="text"],
.page-chat .chat-sidebar .search-contact form .input-wrap input[type="text"],
.bulk-sms-page .group-search-box form .input-wrap input[type="text"],
.scheduler-page .group-search-box form .input-wrap input[type="text"],
.template-page .group-search-box form .input-wrap input[type="text"],
.styled-input-group form .input-wrap select,
.contact-group-page .group-search-box form .input-wrap select,
.page-chat .chat-sidebar .choose-device form .input-wrap select,
.page-chat .chat-sidebar .search-contact form .input-wrap select,
.bulk-sms-page .group-search-box form .input-wrap select,
.scheduler-page .group-search-box form .input-wrap select,
.template-page .group-search-box form .input-wrap select {
  border: none !important;
  -webkit-box-shadow: none;
  box-shadow: none;
  margin: 0;
  padding: 12px 20px;
  font-size: 13px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: block;
  width: 100%;
  padding-right: 36px;
}

.styled-input-group form .input-wrap input[type="text"]:focus,
.contact-group-page .group-search-box form .input-wrap input[type="text"]:focus,
.page-chat .chat-sidebar .choose-device form .input-wrap input[type="text"]:focus,
.page-chat .chat-sidebar .search-contact form .input-wrap input[type="text"]:focus,
.bulk-sms-page .group-search-box form .input-wrap input[type="text"]:focus,
.scheduler-page .group-search-box form .input-wrap input[type="text"]:focus,
.template-page .group-search-box form .input-wrap input[type="text"]:focus,
.styled-input-group form .input-wrap select:focus,
.contact-group-page .group-search-box form .input-wrap select:focus,
.page-chat .chat-sidebar .choose-device form .input-wrap select:focus,
.page-chat .chat-sidebar .search-contact form .input-wrap select:focus,
.bulk-sms-page .group-search-box form .input-wrap select:focus,
.scheduler-page .group-search-box form .input-wrap select:focus,
.template-page .group-search-box form .input-wrap select:focus {
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
}

.styled-input-group form button,
.contact-group-page .group-search-box form button,
.page-chat .chat-sidebar .choose-device form button,
.page-chat .chat-sidebar .search-contact form button,
.bulk-sms-page .group-search-box form button,
.scheduler-page .group-search-box form button,
.template-page .group-search-box form button {
  border: none;
  background: var(--colorprimaylight);
  color: #fff;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  font-size: 25px;
}

.group-search-box form button:hover {
  background: var(--colorprimay);
}

.styled-input-group-alt {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
}

.styled-input-group-alt .dropdwn-option a.toggle-button {
  color: #fff;
  background: var(--colorprimay);
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  line-height: 72px;
  padding: 0px 17px;
}

.styled-input-group-alt form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}

.styled-input-group-alt form .input-wrap {
  border-radius: 20px;
  overflow: hidden;
  width: 100%;
  margin-right: 7px;
  position: relative;
  border: 1px solid #b0afaf;
}

.styled-input-group-alt form .input-wrap:before {
  content: "\eb7b";
  font-family: "LineIcons";
  position: absolute;
  z-index: 1;
  top: 8px;
  right: 13px;
  color: #acaaaa;
  pointer-events: none;
  z-index: 1;
}

.styled-input-group-alt form .input-wrap input[type="text"],
.styled-input-group-alt form .input-wrap select {
  border: none !important;
  -webkit-box-shadow: none;
  box-shadow: none;
  margin: 0;
  padding: 9px 20px;
  font-size: 16px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: block;
  width: 100%;
  padding-right: 36px;
}

.styled-input-group-alt form .input-wrap input[type="text"]:focus,
.styled-input-group-alt form .input-wrap select:focus {
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
}

.context-options {
  position: relative;
  padding-left: 10px;
}

.context-options:hover .context-menu {
  display: block;
}

.context-options a {
  padding: 0px 5px;
  display: inline-block;
}

.context-options .context-menu {
  position: absolute;
  width: auto;
  min-width: 150px;
  border-radius: 10px;
  -webkit-box-shadow: 0px 0px 7px #888686ba;
  box-shadow: 0px 0px 7px #888686ba;
  overflow: hidden;
  padding: 2px 5px;
  background: #fff;
  display: none;
}

.context-options .context-menu.left {
  top: 6px;
  right: 59%;
}

.context-options .context-menu.right {
  left: 100%;
}

.context-options .context-menu button {
  border: none;
  background: #fff;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
  display: block;
  width: 100%;
  padding: 5px 11px;
  border-bottom: 1px solid #acaaaa;
  color: #6c757d;
}

.context-options .context-menu button:hover {
  color: #252525;
}

.context-options .context-menu button:last-child {
  border-bottom: none;
}

.form-control {
  background: var(--backdroundlite) !important;
  font-size: 14px !important;
  /* height: 50px !important;
  line-height: 25px !important; */
}

.form-control:focus {
  color: #495057 !important;
  background-color: #fff !important;
  border-color: var(--backdroundlite) !important;
  outline: 0 !important;
  -webkit-box-shadow: 0 0 0 0.2rem var(--backdroundlite) !important;
  box-shadow: 0 0 0 0.2rem var(--backdroundlite) !important;
}

.form-control.input-rounded {
  border-radius: 22px !important;
}

.form-control.input-rounded-with-btn {
  border-radius: 22px 0px 0px 22px !important;
}

select.form-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url("%PUBLIC_URL%/images/dropdown_button.png") no-repeat;
  background-size: 19px;
  background-position: 99% 14px;
  background: var(--backdroundlite);
}

.btn {
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  border-radius: 20px;
  /* -webkit-box-shadow: 1px 2px 5px #67676769;
  box-shadow: 1px 2px 5px #67676769; */
}

/* .card {
  -webkit-box-shadow: 1px 2px 5px #67676769;
  box-shadow: 1px 2px 5px #67676769;
} */

.btn-success {
  color: #fff !important;
  background-color: var(--colorprimaylight) !important;
  border: none !important;
}

.signin-btn {
  color: black !important;
  background-color: white !important;
}

.white-text {
  color: #fff !important;
  color: #fff !important;
}

.btn-success:hover,
.btn-success:focus {
  background-color: var(--colorprimay) !important;
  border: none !important;
}

.signin-btn:hover,
.btn-success:focus {
  color: black !important;
  background-color: white !important;
}

.chips {
  background: #f4f4f4;
  border: 1px solid #a8a8a8;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  padding: 10px;
  padding-right: 25px;
  border-radius: 7px;
  position: relative;
}

.chips + .chips {
  margin-left: 11px;
}

.chips .chips-dlt {
  position: absolute;
  right: 7px;
  font-size: 14px;
  top: 3px;
  color: #a8a8a8;
}

.chips div {
  font-size: 16px;
  margin: 0px 9px;
}

.chips div em {
  font-style: normal;
  font-size: 14px;
  display: block;
}

/*=============================================================*/
.wrapper {
  position: relative;
}

header {
  padding: 17px 14px;
  background: #fff;
  /* -webkit-box-shadow: -3px 3px 5px #3333331c;
  box-shadow: -3px 3px 5px #3333331c; */
}

.page-area {
  height: 100vh;
  background: white;
  padding-left: 50px;
}

.page-area.sidebar-opened {
  padding-left: 300px;
}

.page-area.sidebar-opened .logo img {
  width: 100px;
  margin: 0 auto;
}

.page-area .common-content-area {
  max-height: calc(100vh - 89px);
  overflow: auto;
}

.page-sidebar {
  background-color: var(--colorprimay);
  position: absolute;
  left: 0;
  top: 0;
  width: 65px;
}

.page-sidebar .mCSB_inside > .mCSB_container {
  margin-right: 0;
}

.page-sidebar .logo {
  padding: 28px 0px;
  background: var(--colorprimay);
}

.page-sidebar .logo img {
  width: 55px;
  margin: 0 auto;
  display: block;
}

.page-sidebar.sidebar-opened {
  width: 300px;
  min-height: 100vh;
}

.page-sidebar.sidebar-opened .logo {
  padding: 20px 0px !important;
}

.page-sidebar.sidebar-opened .logo img {
  width: 100px;
  margin: 0 auto;
}

.page-sidebar.sidebar-opened .menu-wrapper {
  height: 72vh;
}

.page-sidebar.sidebar-opened .primary-nav li a {
  padding: 1.5rem 1rem;
}

.page-sidebar.sidebar-opened .primary-nav li a span {
  display: inline-block;
  opacity: 1;
  font-size: 14px;
  vertical-align: middle;
}

.page-sidebar .menu-wrapper {
  height: calc(100vh - 150px);
  /* background-image: url("./../images/sidebar_bg.png"); */
  background-repeat: no-repeat;
  background-position: 100%;
  background-size: cover;
}

.page-sidebar .sidebar-collapse-button {
  padding: 22px;
  text-align: center;
  border-top: 1px solid var(--hoverActive) !important;
  border-bottom: 1px solid var(--hoverActive) !important;
}

.page-sidebar .sidebar-collapse-button a {
  color: #fff !important;
}

.page-sidebar .sidebar-collapse-button a.active i {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.page-sidebar .primary-nav {
  display: block;
  list-style: none;
}

.page-sidebar .primary-nav li a {
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  color: #1a1a1a;
  font-weight: 400;
  line-height: normal;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  text-decoration: none;
  display: block;
  padding: 1.2rem 1.2rem;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
}

.page-sidebar .primary-nav li a i {
  display: inline-block;
  margin-right: 3px;
}

.page-sidebar .primary-nav li a span {
  display: none;
  opacity: 0;
  font-size: 0;
}

.page-sidebar .primary-nav li a:hover {
  background: var(--colorprimay);
  border-color: var(--hoverActive) !important;
  color: #ffc107;
}

.page-sidebar .primary-nav li.active a {
  background: #fafafa;
  border-color: var(--hoverActive) !important;
  color: #fafafa;
}

.page-sidebar .primary-nav .toggle li a:hover {
  background: var(--colorprimay);
  border-color: var(--hoverActive) !important;
  color: #ffc107;
}

.page-sidebar .primary-nav .toggle li.active a {
  background: #162d95;
  border-color: #56b59d;
  color: #ff07c1;
}

.right-sidebar {
  pointer-events: none;
  left: 0;
  right: 0;
  z-index: 9;
  top: 0;
  bottom: 0;
  position: fixed;
  background: #0000009e;
  opacity: 0;
  -webkit-transition: all 0.1s ease;
  transition: all 0.1s ease;
}

.right-sidebar.opened {
  opacity: 1;
  pointer-events: all;
}

.right-sidebar .right-sidebar-body {
  width: 70%;
  float: right;
  background: var(--backdroundlite);
  height: 100vh;
}

.right-sidebar .right-sidebar-body header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.right-sidebar .right-sidebar-body .right-sidebar-content {
  padding: 20px 15px;
  max-height: calc(100vh - 79px);
  overflow: auto;
}

.contact-group-page {
  padding: 10px 15px 0px;
}

.contact-group-page .add-contact-form-wrap {
  padding: 15px;
}

.contact-group-page .add-contact-form-wrap .file-drop-zone {
  max-width: 300px;
  padding: 1rem;
  margin: 10px;
  height: 200px;
  border: 1px solid #e3e3e3;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.contact-group-page .add-contact-form-wrap .file-drop-zone i {
  width: 35px;
  text-align: center;
  line-height: 30px;
  border: 1px solid #e3e3e3;
  border-radius: 50%;
  margin-bottom: 20px;
  height: 35px;
  color: var(--colorprimay);
}

.contact-group-page .group-card-wrapper .group-card-wrapper-inner {
  max-height: calc(100vh - 170px);
}

.contact-group-page .group-card-wrapper .mCSB_draggerRail {
  display: none !important;
}

.contact-group-page .group-card-wrapper .mCSB_scrollTools .mCSB_draggerContainer {
  background: #cfe8e0;
  border: 1px solid var(--dataTableHead);
  width: 14px;
  border-radius: 7px;
  padding: 9px 0px;
}

.contact-group-page .group-card-wrapper .mCSB_dragger_bar {
  -webkit-box-shadow: 0px 3px 4px #0000007d;
  box-shadow: 0px 3px 4px #0000007d;
  background-color: var(--colorprimay) !important;
  width: 7px !important;
}

.contact-group-page .group-card-wrapper .card {
  border: none;
  border-radius: 14px;
  -webkit-box-shadow: 0px 0px 20px #3333332e;
  box-shadow: 0px 0px 20px #3333332e;
  margin: 10px;
  border: 2px solid transparent;
  cursor: pointer;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.contact-group-page .group-card-wrapper .card:hover,
.contact-group-page .group-card-wrapper .card.active {
  border-color: var(--colorprimay) !important;
}

.contact-group-page .group-card-wrapper .card:hover .card-title h5,
.contact-group-page .group-card-wrapper .card.active .card-title h5 {
  color: var(--colorprimay) !important;
}

.contact-group-page .group-card-wrapper .card .card-title {
  margin-bottom: 18px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.contact-group-page .group-card-wrapper .card .card-title h5 {
  white-space: normal;
  word-break: break-word;
  padding-right: 4px;
}

.contact-group-page .group-card-wrapper .card .card-title a {
  color: #a8a8a8;
}

.contact-group-page .group-card-wrapper .card .card-subtitle {
  font-size: 14px;
}

.contact-group-page .group-details {
  padding: 0 15px;
}

.custom-data-table {
  border-collapse: separate;
  border-spacing: 0 15px !important;
  border-bottom: 0 !important;
}

.custom-data-table tbody > tr.selected,
.custom-data-table tbody > tr > .selected {
  background-color: var(--backdroundlite) !important;
}

.custom-data-table th {
  border: none !important;
  background: var(--colorprimaylight) !important;
  overflow: hidden;
  padding: 13px 29px;
}

.custom-data-table th:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.custom-data-table th:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.custom-data-table tr td {
  border: none;
  padding: 11px 29px;
}

.custom-data-table tbody tr {
  -webkit-box-shadow: 1px 1px 7px #00000059;
  box-shadow: 1px 1px 7px #00000059;
  border: none;
}

.page-chat {
  overflow-x: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

.page-chat .active-chat-contact-details {
  text-align: center;
}

.page-chat .active-chat-contact-details .contact-name {
  font-size: 18px;
}

.page-chat .active-chat-contact-details span.contact-number {
  font-size: 14px;
  margin: 3px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.page-chat .active-chat-contact-details span.contact-number em {
  display: inline-block;
  font-style: normal;
  margin: 0 auto;
  -webkit-box-flex: 2;
  -ms-flex: 2;
  flex: 2;
  text-align: center;
}

.page-chat .active-chat-contact-details span.contact-number a {
  display: inline-block;
  margin-left: auto;
  color: #a8a8a8;
  font-size: 16px;
  margin-top: -2px;
}

.page-chat .chat-sidebar {
  background: #fff;
  width: 420px;
  height: 100vh;
  position: fixed;
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
  -webkit-transition: all 0.1s ease;
  transition: all 0.1s ease;
}

.page-chat .chat-sidebar + .page-wrapper {
  width: 100%;
}

.page-chat .chat-sidebar.pulled {
  -webkit-transform: none;
  transform: none;
}

.page-chat .chat-sidebar.pulled + .page-wrapper {
  width: calc(100% - 420px);
  margin-right: auto;
}

.page-chat .chat-sidebar .choose-device {
  margin-bottom: 10px;
}

.page-chat .chat-sidebar .choose-device form {
  padding-right: 0;
}

.page-chat .chat-sidebar .choose-device .input-wrap:before {
  content: "\ea58" !important;
  color: var(--hoverActive) !important;
}

.page-chat .chat-sidebar .search-contact {
  margin-bottom: 10px;
}

.page-chat .chat-sidebar .search-contact form {
  padding-right: 0;
}

.page-chat .chat-sidebar .search-contact form .input-wrap {
  width: 100%;
}

.page-chat .chat-sidebar .contact-list {
  max-height: calc(100vh - 160px);
  overflow-y: auto;
  scrollbar-width: none;
}

.page-chat .chat-sidebar .contact-list::-webkit-scrollbar {
  display: none;
}

.page-chat .chat-sidebar .contact-list ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.page-chat .chat-sidebar .contact-list ul li.contact-list-item {
  border-bottom: 1px solid #eae9e9;
}

.page-chat .chat-sidebar .contact-list ul li.contact-list-item a {
  padding: 11px 15px;
  text-decoration: none !important;
  color: #000;
  -webkit-transition: all 0.1s ease;
  transition: all 0.1s ease;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.page-chat .chat-sidebar .contact-list ul li.contact-list-item a .avatar {
  width: 55px;
  height: 55px;
  overflow: hidden;
  border-radius: 50%;
}

.page-chat .chat-sidebar .contact-list ul li.contact-list-item a .avatar img {
  max-width: 100%;
  display: block;
  margin: 0 auto;
}

.page-chat .chat-sidebar .contact-list ul li.contact-list-item a .details {
  width: calc(100% - 55px);
  padding: 0px 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.page-chat .chat-sidebar .contact-list ul li.contact-list-item a .details div {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.page-chat .chat-sidebar .contact-list ul li.contact-list-item a .details h5 {
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 5px;
}

.page-chat .chat-sidebar .contact-list ul li.contact-list-item a .details .msg,
.page-chat .chat-sidebar .contact-list ul li.contact-list-item a .details .time-stamp {
  font-size: 14px;
  font-weight: 300;
}

.page-chat .chat-sidebar .contact-list ul li.contact-list-item a:hover,
.page-chat .chat-sidebar .contact-list ul li.contact-list-item a.active {
  background: var(--colorprimay);
}

.page-chat .chat-sidebar .contact-list ul li.contact-list-item a:hover h5,
.page-chat .chat-sidebar .contact-list ul li.contact-list-item a:hover span,
.page-chat .chat-sidebar .contact-list ul li.contact-list-item a.active h5,
.page-chat .chat-sidebar .contact-list ul li.contact-list-item a.active span {
  color: #fff;
}

.page-chat .chat-body {
  height: calc(100vh - 163px);
  overflow-y: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: 20px 0px;
}

.page-chat .chat-body::-webkit-scrollbar {
  display: none;
}

.page-chat .chat-body .meta-timeline {
  text-align: center;
}

.page-chat .chat-body .meta-timeline span {
  display: inline-block;
  background: #8f8f8f;
  color: #fff;
  font-size: 12px;
  padding: 2px 6px;
  border-radius: 8px;
  -webkit-box-shadow: 1px 1px 6px #6d6b6bc4;
  box-shadow: 1px 1px 6px #6d6b6bc4;
}

.page-chat .chat-body .chat-item {
  margin: 15px 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 6px 12px;
  -webkit-transition: all 0.1s ease;
  transition: all 0.1s ease;
}

.page-chat .chat-body .chat-item.selected {
  background: #a8e3d1;
  padding: 8px 15px;
}

.page-chat .chat-body .chat-item .timestamp {
  display: block;
  text-align: right;
  font-size: 10px;
  margin: 3px 4px;
}

.page-chat .chat-body .chat-item.message-in {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.page-chat .chat-body .chat-item.message-in > div {
  background: #fff;
  padding: 6px 15px;
  font-size: 14px;
  border-radius: 30px;
  border: 1px solid #ebebeb;
}

.page-chat .chat-body .chat-item.message-out {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.page-chat .chat-body .chat-item.message-out > div {
  background: var(--colorprimay);
  padding: 6px 15px;
  font-size: 14px;
  border-radius: 30px;
  border: 1px solid #ebebeb;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: start;
  color: #fff;
}

.page-chat .chat-body .chat-item.message-out > div .timestamp {
  color: #bed0c9 !important;
}

.page-chat .chat-body .chat-item.message-out > div .timestamp img {
  width: 20px;
}

.page-chat .chat-body .chat-item > div {
  max-width: 65%;
  width: auto;
}

.page-chat .chat-input-area {
  background: #ebebeb;
  padding: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.page-chat .chat-input-area .chat-input-box {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  padding: 12px;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #fff;
  border-radius: 20px;
  overflow: hidden;
}

.page-chat .chat-input-area .chat-input-box textarea {
  border: none;
  background-color: transparent;
  width: 100%;
  height: 50px;
  padding: 10px;
  padding-right: 40px;
  overflow-y: auto;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.page-chat .chat-input-area .chat-input-box .file-uploader {
  font-size: 25px;
  opacity: 0.4;
}

.page-chat .chat-input-area .chat-input-box .file-uploader input[type="file"] {
  display: none;
}

.page-chat .chat-input-area .chat-input-box .file-uploader i {
  cursor: pointer;
}

.page-chat .chat-input-area button {
  font-size: 18px;
  text-align: center;
  line-height: 42px;
  margin: 0px 15px;
}

.login-wrapper {
  background: url("%PUBLIC_URL%/images/login_signup_bg_1.png");
  height: 100vh;
  background-size: cover;
}

.login-wrapper .login-card-wrapper {
  position: relative;
}

.login-wrapper .login-card-wrapper .card {
  -webkit-box-shadow: 3px 3px 8px #00000075;
  box-shadow: 3px 3px 8px #00000075;
  border: none;
  border-radius: 6px !important;
  overflow: hidden;
}

.login-wrapper .login-card-wrapper .left-block {
  padding: 50px 32px !important;
  text-align: center;
}

.login-wrapper .login-card-wrapper .left-block h2 {
  color: var(--colorprimay);
}

.login-wrapper .login-card-wrapper .right-block {
  background: url("%PUBLIC_URL%/images/login_signup_bg_2.png");
  background-size: cover;
  text-align: center;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 70px !important;
}

label {
  color: #1a1a1a;
}

.signup-wrapper {
  background: url("%PUBLIC_URL%/images/login_signup_bg_1.png");
  height: 100vh;
  background-size: cover;
}

.signup-wrapper .form-control::-webkit-input-placeholder {
  color: gray;
  opacity: 0.8;
}

.signup-wrapper .form-control:-moz-placeholder {
  color: gray;
  opacity: 0.8;
}

.signup-wrapper .form-control::-moz-placeholder {
  color: gray;
  opacity: 0.8;
}

.signup-wrapper .form-control:-ms-input-placeholder {
  color: gray;
  opacity: 0.8;
}

.signup-wrapper .login-card-wrapper {
  position: relative;
}

.signup-wrapper .login-card-wrapper .card {
  -webkit-box-shadow: 3px 3px 8px #00000075;
  box-shadow: 3px 3px 8px #00000075;
  border: none;
  border-radius: 6px !important;
  overflow: hidden;
}

.signup-wrapper .login-card-wrapper .left-block {
  padding: 50px 32px !important;
  text-align: center;
}

.signup-wrapper .login-card-wrapper .left-block h2 {
  color: var(--colorprimay);
}

.signup-wrapper .login-card-wrapper .right-block {
  position: relative;
  background: url("%PUBLIC_URL%/images/login_signup_bg_2.png");
  background-size: cover;
  text-align: center;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 70px !important;
}

.signup-wrapper .login-card-wrapper .right-block .logo {
  width: 61px;
  height: auto;
  position: absolute;
  top: 16px;
  left: 17px;
}

.page-profile .profile-wrapper {
  padding-top: 30px;
}

.page-profile .profile-wrapper .avatar {
  width: 90px;
  height: 90px;
  margin: 0 auto;
  border-radius: 50%;
  border: 5px solid var(--colorprimaylight);
  position: relative;
  -webkit-box-shadow: 0px 0px 0px 5px var(--hoverActive);
  box-shadow: 0px 0px 0px 5px var(--hoverActive);
  position: relative;
}

.page-profile .profile-wrapper .avatar img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.page-profile .profile-wrapper .avatar .upload-avatar {
  background: var(--colorprimay);
  width: 25px;
  height: 25px;
  line-height: 26px;
  text-align: center;
  border-radius: 50%;
  color: #fff;
  position: absolute;
  bottom: -15px;
  right: -8px;
  -webkit-box-shadow: 0px 0px 4px #5a5a5a;
  box-shadow: 0px 0px 4px #5a5a5a;
  cursor: pointer;
}

.page-profile .profile-wrapper .avatar .upload-avatar:hover {
  background: #333333;
}

.page-profile .profile-wrapper .button-set {
  width: 260px;
  margin: 0 auto;
}

.bulk-sms-page {
  padding: 10px 15px 0px;
}

.bulk-sms-page .sms-status span {
  display: inline-block;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: 10px;
}

.bulk-sms-page .sms-status span img {
  width: 20px;
}

.bulk-sms-page .group-card-wrapper .group-card-wrapper-inner {
  max-height: calc(100vh - 170px);
}

.bulk-sms-page .group-card-wrapper .mCSB_draggerRail {
  display: none !important;
}

.bulk-sms-page .group-card-wrapper .mCSB_scrollTools .mCSB_draggerContainer {
  background: #cfe8e0;
  border: 1px solid var(--dataTableHead);
  width: 14px;
  border-radius: 7px;
  padding: 9px 0px;
}

.bulk-sms-page .group-card-wrapper .mCSB_dragger_bar {
  -webkit-box-shadow: 0px 3px 4px #0000007d;
  box-shadow: 0px 3px 4px #0000007d;
  background-color: var(--colorprimay) !important;
  width: 7px !important;
}

.bulk-sms-page .group-card-wrapper .card {
  border: none;
  border-radius: 14px;
  -webkit-box-shadow: 0px 0px 20px #3333332e;
  box-shadow: 0px 0px 20px #3333332e;
  margin: 10px;
  border: 2px solid transparent;
  cursor: pointer;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.bulk-sms-page .group-card-wrapper .card:hover,
.bulk-sms-page .group-card-wrapper .card.active {
  border-color: var(--colorprimay) !important;
}

.bulk-sms-page .group-card-wrapper .card:hover .card-title h5,
.bulk-sms-page .group-card-wrapper .card.active .card-title h5 {
  color: var(--colorprimay) !important;
}

.bulk-sms-page .group-card-wrapper .card .card-title {
  margin-bottom: 18px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.bulk-sms-page .group-card-wrapper .card .card-title h5 {
  white-space: normal;
  word-break: break-word;
  padding-right: 4px;
}

.bulk-sms-page .group-card-wrapper .card .card-title a {
  color: #a8a8a8;
}

.bulk-sms-page .group-card-wrapper .card .card-subtitle {
  font-size: 14px;
}

.bulk-sms-page .group-details {
  padding: 0 5px;
}

.scheduler-page {
  padding: 10px 15px 0px;
}

.scheduler-page .sms-status span {
  display: inline-block;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: 10px;
}

.scheduler-page .sms-status span img {
  width: 20px;
}

.scheduler-page .group-card-wrapper .group-card-wrapper-inner {
  max-height: calc(100vh - 170px);
}

.scheduler-page .group-card-wrapper .mCSB_draggerRail {
  display: none !important;
}

.scheduler-page .group-card-wrapper .mCSB_scrollTools .mCSB_draggerContainer {
  background: #cfe8e0;
  border: 1px solid var(--dataTableHead);
  width: 14px;
  border-radius: 7px;
  padding: 9px 0px;
}

.scheduler-page .group-card-wrapper .mCSB_dragger_bar {
  -webkit-box-shadow: 0px 3px 4px #0000007d;
  box-shadow: 0px 3px 4px #0000007d;
  background-color: var(--colorprimay) !important;
  width: 7px !important;
}

.scheduler-page .group-card-wrapper .card {
  border: none;
  border-radius: 14px;
  -webkit-box-shadow: 0px 0px 20px #3333332e;
  box-shadow: 0px 0px 20px #3333332e;
  margin: 10px;
  border: 2px solid transparent;
  cursor: pointer;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.scheduler-page .group-card-wrapper .card:hover,
.scheduler-page .group-card-wrapper .card.active {
  border-color: var(--colorprimay) !important;
}

.scheduler-page .group-card-wrapper .card:hover .card-title h5,
.scheduler-page .group-card-wrapper .card.active .card-title h5 {
  color: var(--colorprimay) !important;
}

.scheduler-page .group-card-wrapper .card .card-title {
  margin-bottom: 18px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.scheduler-page .group-card-wrapper .card .card-title h5 {
  white-space: normal;
  word-break: break-word;
  padding-right: 4px;
}

.scheduler-page .group-card-wrapper .card .card-title a {
  color: #a8a8a8;
}

.scheduler-page .group-card-wrapper .card .card-subtitle {
  font-size: 14px;
}

.scheduler-page .group-details {
  padding: 0 15px;
}

.logout-dialouge {
  position: fixed;
  z-index: 99;
  background: #000000b0;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.logout-dialouge .logout-popup {
  width: 700px;
  /* background: url(./../images/logout_bg.png); */
  text-align: center;
  padding: 5rem 7rem;
  border-radius: 10px;
  background-size: cover;
  -webkit-box-shadow: 1px 1px 5px #0000004f;
  box-shadow: 1px 1px 5px #0000004f;
}

.logout-dialouge .logout-popup h5 {
  color: #fff;
  margin-bottom: 20px;
}

.template-page {
  padding: 10px 15px 0px;
}

.template-page .sms-status span {
  display: inline-block;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: 10px;
}

.template-page .sms-status span img {
  width: 20px;
}

.template-page .group-card-wrapper .group-card-wrapper-inner {
  max-height: calc(100vh - 170px);
}

.template-page .group-card-wrapper .mCSB_draggerRail {
  display: none !important;
}

.template-page .group-card-wrapper .mCSB_scrollTools .mCSB_draggerContainer {
  background: #cfe8e0;
  border: 1px solid var(--dataTableHead);
  width: 14px;
  border-radius: 7px;
  padding: 9px 0px;
}

.template-page .group-card-wrapper .mCSB_dragger_bar {
  -webkit-box-shadow: 0px 3px 4px #0000007d;
  box-shadow: 0px 3px 4px #0000007d;
  background-color: var(--colorprimay) !important;
  width: 7px !important;
}

.template-page .group-card-wrapper .card {
  border: none;
  border-radius: 14px;
  -webkit-box-shadow: 0px 0px 20px #3333332e;
  box-shadow: 0px 0px 20px #3333332e;
  margin: 10px;
  border: 2px solid transparent;
  cursor: pointer;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.template-page .group-card-wrapper .card:hover,
.template-page .group-card-wrapper .card.active {
  border-color: var(--colorprimay) !important;
}

.template-page .group-card-wrapper .card:hover .card-title h5,
.template-page .group-card-wrapper .card.active .card-title h5 {
  color: var(--colorprimay);
}

.template-page .group-card-wrapper .card .card-title {
  margin-bottom: 18px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.template-page .group-card-wrapper .card .card-title h5 {
  white-space: normal;
  word-break: break-word;
  padding-right: 4px;
}

.template-page .group-card-wrapper .card .card-title a {
  color: #a8a8a8;
}

.template-page .group-card-wrapper .card .card-subtitle {
  font-size: 14px;
}

.template-page .group-details {
  padding: 0 15px;
}

/*# sourceMappingURL=style.css.map */

/* Css by Khushali */
.ant-form-item-label label {
  font-size: 1rem !important;
  color: #1a1a1a !important;
  display: inline-block !important;
  margin-bottom: 0.5rem !important;
}

.ant-input:focus,
.ant-input:hover {
  background-color: white !important;
  border-color: var(--backdroundlite) !important;
  border-right-width: 1px;
}

.text-area-focus:focus {
  background-color: white !important;
  border-color: var(--backdroundlite) !important;
  /* box-shadow: 0 0 0 0.2rem var(--backdroundlite) !important; */
}

.right-sidebar .right-sidebar-body header i {
  font-size: 28px;
}

/* .ant-btn:focus, .ant-btn:hover {
  color: #40a9ff !important;
  background-color: #243445 !important;
  box-shadow: none !important;
  outline: none !important;
} */

.scroll-content .card-body {
  padding: 0.5rem;
}

.scroll-content .card-body .card-title h5 {
  font-size: 14px;
}

.scroll-content .sms-status.mt-4 {
  margin-top: 0.5rem !important;
}

.scroll-content .card-body .card-title {
  margin-bottom: 10px !important;
}

.payment-options img {
  border: 1px solid #ddd;
}

.dropdwn-option {
  display: none;
}

.guni-green-btn {
  background-color: var(--colorprimaylight) !important;
  border: none !important;
}

.guni-green-btn:hover {
  background-color: var(--colorprimay) !important;
  border: none !important;
}

.guni-blue-btn {
  background-color: #3683ff !important;
  border: none !important;
}

.guni-blue-btn:hover {
  background-color: #5d9afc !important;
  border: none !important;
}

.fix-message-width {
  max-width: 18vw;
}

.fix-sender-width {
  max-width: 5vw;
}

.fix-date-width {
  max-width: 6vw;
}

.fix-status-width {
  max-width: 4vw;
}

.fix-button-action-width {
  max-width: 4.5vw;
}

.fix-action-width {
  max-width: 2vw;
}

.align-center-icon {
  vertical-align: -0.5em !important;
  margin-right: 0px !important;
  margin-left: 5px !important;
  padding-left: 0px !important;
  color: var(--colorprimaylight) !important;
}

*.icon-blue {
  color: #0088cc;
}

*.icon-grey {
  color: grey;
}

/* RESPONSIVE CSS START HERE */

@media (max-width: 576px) {
}

@media screen and (min-device-width: 577px) and (max-device-width: 768px) {
}

@media screen and (min-device-width: 769px) and (max-device-width: 1023px) {
}

@media screen and (min-device-width: 1024px) and (max-device-width: 1367px) {
  .quick-sms-btn,
  .buy-credit {
    display: inline-block;
    margin: 0 3px !important;
    padding: 0 5px !important;
    font-size: 14px !important;
  }

  .current-credit-btn {
    padding: 0 5px !important;
    margin: 0 3px !important;
    font-size: 14px !important;
    border-radius: 10px;
  }

  .fix-button-action-width {
    max-width: 7.3vw;
  }
}
