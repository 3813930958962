.wrapper {
  overflow-x: hidden;
  background-color: var(--bg-color);
}

.toggler {
  cursor: pointer;
  width: 50px;
  background-size: 100%;
  font-size: 22px;
  height: 56px !important;
  text-align: center;
  line-height: 60px;
  top: 0;
  left: 164px;
  z-index: 9;
  position: absolute;
  border-radius: 50%;
  color: var(--white-color);
  transition: left 0.15s ease-in-out;
}

.toggler.active {
  left: 10px;
  transition: left 0.15s ease-in-out;
}

/* new */
.sidebar {
  z-index: 100;
  height: 100%;
  position: relative;
  min-height: 100vh;
  width: 70px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  background-image: linear-gradient(180deg, var(--main-color), var(--second-bg-color));
  -webkit-transition: width 0.2s ease-out;
  -moz-transition: width 0.2s ease-out;
  -o-transition: width 0.2s ease-out;
  transition: width 0.2s ease-out;
}

.sidebar.active {
  width: 215px;
  -webkit-transition: width 0.2s ease-out;
  -moz-transition: width 0.2s ease-out;
  -o-transition: width 0.2s ease-out;
  transition: width 0.2s ease-out;
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 56px;
  border-bottom: 1px solid #ffffff;
}

.list {
  display: flex;
  justify-content: center;
  min-height: calc(100vh - 115px);
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0.2rem;
  margin-top: 0.2rem;
  width: 70px;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.list.active {
  justify-content: flex-start;
  width: 215px;
  overflow-x: inherit;
  overflow-y: scroll;
  height: 300px;
}

.list::-webkit-scrollbar {
  display: none;
}

.item {
  display: flex;
  align-items: center;
  width: 90%;
  height: 30px;
  font-size: 15px;
  margin-bottom: 0.4rem;
  margin-top: 0.4rem;
  border-radius: 8px;
  color: var(--white-color);
  cursor: pointer;
  position: relative;
}

.item i {
  width: 40px;
  text-align: center;
}

.item .label {
  color: var(--white-color);
  width: 150px;
}

.item.active,
.item:hover {
  background-color: var(--white-color);
  color: var(--main-color);
}

.item.small {
  width: 46px;
  justify-content: center;
}

.item.active .label,
.item:hover .label {
  color: var(--main-color);
}

.listItems {
  color: white;
  width: 100%;
  font-size: 18px;
}

.listItem {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 40px;
  height: 40px;
  margin-bottom: 0.2rem;
  margin-top: 0.2rem;
  position: relative;
}

.listItemLink:hover > .listItem,
.listItem:hover {
  color: var(--main-color);
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 5px 30px -10px rgb(31 38 135 / 37%);
  backdrop-filter: blur(1px);
  -webkit-backdrop-filter: blur(1px);
}

.listItemLink {
  color: white !important;
}

.listItem:hover .listItemLink {
  color: var(--main-color) !important;
}

.subList {
  display: flex;
  justify-content: flex-start;
  flex-flow: column;
  width: 0;
  max-height: 0px;
  background: transparent;
  box-shadow: none;
  border-radius: 10px;
  border: none;
  font-size: 0px;
  position: absolute;
  left: 0px;
  border-radius: 8px;
  top: 36px;
  -webkit-transition: max-height 0.2s ease-out;
  -moz-transition: max-height 0.2s ease-out;
  -o-transition: max-height 0.2s ease-out;
  transition: max-height 0.2s ease-out;
  z-index: 1;
  overflow: hidden;
}

.subList.active {
  font-size: 16px;
  animation: fadeIn 0.2s ease-in-out;
  box-shadow: 0 5px 30px -10px rgb(31 38 135 / 37%);
  backdrop-filter: blur(1px);
  -webkit-backdrop-filter: blur(1px);
  -webkit-transition: max-height 0.2s ease-out;
  -moz-transition: max-height 0.2s ease-out;
  -o-transition: max-height 0.2s ease-out;
  transition: max-height 0.2s ease-out;
  max-height: 500px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  background: rgba(255, 255, 255, 0.9);
  width: max-content;
}

.subList .listItemLink {
  margin: 0.2rem;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.subList .listItem {
  width: 140px;
  margin: 0rem;
  color: var(--main-color);
  justify-content: start;
  height: 25px;
  font-size: 12px;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.subList .listItem:hover {
  background-color: var(--main-color);
  color: #ffffff;
}

.listItemText {
  cursor: pointer;
  color: #ffffff;
  padding: 0.2rem 1em;
  width: 151px;
  font-size: 15px;
  margin-bottom: 0.2rem;
  margin-top: 0.2rem;
}

.listItemLink:hover > .listItemText,
.listItemText:hover,
.listItemText.active {
  color: var(--main-color);
  padding: 0.2rem 1em;
  border-radius: 8px;
  background-color: #ffffff;
}

.listItems.active {
  width: 70px;
}

.menuWidth {
  width: auto !important;
  left: 32px;
  background-color: transparent !important;
  box-shadow: none !important;
  border-right: none !important;
  border-top: none !important;
  border-bottom: none !important;
  border-radius: 0;
  border-left: 1px solid #ffffff !important;
}

.menuWidth .listItemLink .listItem {
  color: #ffffff !important;
}

.menuWidth .listItemLink .listItem:hover {
  color: #ffffff !important;
}

/* Loader Animation */

@-webkit-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}

.text {
  -webkit-animation-duration: 1.25s;
  animation-duration: 1.25s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: placeHolderShimmer;
  animation-name: placeHolderShimmer;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  background: #e6e6e6;
  background: linear-gradient(to right, #f6f6f6 8%, #ffffff 18%, #f6f6f6 33%);
  background-size: 800px 104px;
  height: 35px;
  width: 35px;
  position: relative;
}

.big {
  width: 80px;
}
