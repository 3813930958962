.container {
  background-image: url("../../assets/images/phoneframe/frame.png");
  background-size: auto 500px;
  background-repeat: no-repeat;
  background-position: center;
  height: 505px;
  width: 250px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;

  .headerName h5 {
    position: relative;
    text-align: center;
    border-bottom: 1px solid #c7c7c7;
    width: 87%;
    margin: 1rem auto;
    z-index: 1;
    padding-top: 2rem;
    padding-bottom: 1rem;
  }

  .chatBox {
    height: 390px;
    overflow: auto;
    display: flex;
    margin: auto;
    width: 221px;
    flex-direction: column;
    align-items: flex-end;
  }
}
.containerSmall {
  background-image: url("../../assets/images/phoneframe/halfframe.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 240px;
  width: 300px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;

  .headerName h5 {
    position: relative;
    text-align: center;
    border-bottom: 1px solid #c7c7c7;
    width: 70%;
    margin: 1rem auto;
    z-index: 1;
    padding-top: 2rem;
    padding-bottom: 1rem;
  }

  .chatBox {
    height: 135px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin: 0 2.1rem;
  }
}
