.btn {
  background-image: linear-gradient(180deg, var(--appMainDarkColor), var(--main-color));
  border: none;
  color: var(--white-color);
  text-transform: capitalize;
  font-size: xx-small;
}
.btn:hover {
  background-image: linear-gradient(180deg, var(--main-color), var(--appMainDarkColor));
}
