body {
  background-color: #f0f6ff;
}

.linkHover {
  color: rgb(33, 105, 225);
  text-decoration: underline;
  cursor: pointer;
  transition: color 0.3s ease; /* Smooth transition */
}
.linkHover:hover,
.linkHover:focus {
  color: rgb(4, 28, 200);
  text-decoration: none;
}

.loginCol {
  height: 80vh;
}

.checkBoxBgInfo {
  accent-color: #149cbe;
}

.signInLink {
  position: absolute;
  right: 40px;
  bottom: 0px;
  font-size: 13px;
}

.loginContainer {
  height: max-content;
  max-width: 35em;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  padding: 2rem;
  background-color: white;
  margin: 4rem auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.parentContainer {
  height: 100vh;
  width: 100%;
  padding: 3rem;
}

.formContainer {
  padding: 5%;
  width: 27rem;
}

.container {
  height: 100%;
}

.imageContainer {
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.guniLogo {
  width: 7.5em;
}

.signInLogo {
  width: 35em;
}

.inputRound input {
  border-radius: 2rem;
}

.loginsocialicon {
  height: 40px;
}

.loginsocialicon button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
}

.loginsocialicon span {
  font-size: 11px;
  font-weight: bold;
  white-space: nowrap !important;
}

.loginsocialicon img {
  width: 18px;
}

.loginInputStyle {
  background-color: #ffffff !important;
  border: 1px solid #cccccc !important;
}

.otpInput {
  width: 2.5em !important;
  text-align: center !important;
}

@media (max-width: 768px) {
  body {
    background: white;
  }

  .guniLogo {
    width: 7.5em;
  }

  .signInLogo {
    width: 20em;
  }

  .formContainer {
    padding: 0%;
  }
}

.ContactContainer {
  position: relative;
}

.ContactSelectBox {
  position: absolute;
  z-index: 9;
  height: 27px;
  border: 1px solid #149cbe;

  /* remove this when dropdown required */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.ContactInput {
  padding-left: 70px !important;
  /* LogoSlideshow.module.css */
}

.slideshowContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 150px; /* Adjust height as needed */
  overflow: hidden;
}

.logoWrapper {
  position: absolute;
  transition: transform 1s ease-in-out, opacity 1s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logoImage {
  max-height: 80px;
  max-width: 80px;
  object-fit: contain;
  transition: transform 1s ease-in-out;
}

/* Positioning for the four logos */
.left {
  transform: translateX(-150%) scale(0.8);
  opacity: 0.5;
}

.center {
  transform: translateX(0%) scale(1.3);
  opacity: 1;
  z-index: 2;
}

.right {
  transform: translateX(150%) scale(0.8);
  opacity: 0.5;
}

.farRight {
  transform: translateX(300%) scale(0.8);
  opacity: 0.3;
}

.loginsocialicon {
  margin-bottom: 16px;
}

.socialButton {
  width: 15rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 16px;
  border-radius: 8px;
  transition: background-color 0.3s ease;
  font-size: 16px;
  font-weight: 500;
}

.socialButton:hover {
  background-color: #f0f0f0;
}

.socialButton img {
  margin-left: 8px;
  width: 24px;
}

@media (max-width: 768px) {
  .slideshowContainer {
    height: 100px;
  }

  .logoImage {
    max-height: 60px;
    max-width: 60px;
  }

  .left {
    transform: translateX(-120%) scale(0.7);
  }

  .right {
    transform: translateX(120%) scale(0.7);
  }

  .farRight {
    transform: translateX(240%) scale(0.5);
    opacity: 0.3;
  }
}

@media (max-width: 425px) {
  .loginContainer {
    font-size: 10.5px;
    padding: 1rem;
    width: 100%;
  }

  .signInLink {
    right: 5px;
    font-size: 12px;
  }

  .container {
    height: auto;
  }

  .loginCol {
    font-size: 11px;
    height: 100%;
  }

  .loginsocialicon span {
    font-size: 9px;
  }
}

@media (max-width: 320px) {
  .formContainer {
    padding: 5%;
  }

  .loginContainer {
    width: 32em;
  }

  .parentContainer {
    padding: 0rem;
  }

  .signInLink {
    right: -38px;
  }
}
