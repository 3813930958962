.formInputPrimary {
  input,
  textarea {
    border: 1px solid #343a40;
    border-radius: 4px;
    padding: 10px 20px;
    width: 100%;
    background: transparent;

    &:hover,
    &:focus {
      border-color: var(--main-color) !important;
    }
  }
}

.formInputSecondary {
  input,
  textarea {
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    width: 100%;
    background: var(--appMainLightColor);

    &:hover,
    &:focus {
      border: none;
    }
  }
}

.formSelectInputContainer {
  width: 100%;

  .formSelectInputPrimary {
    width: 100%;

    & > div:first-child {
      border: 1px solid var(--main-color);
      border-radius: 4px;
      padding: 0px 10px;
      width: 100%;
      background: transparent;

      &:hover,
      &:focus {
        border-color: var(--main-color);
      }
    }
  }

  .formSelectInputSecondary {
    width: 100%;
    background-color: var(--appMainLightColor);

    & > div:first-child {
      border: none;
      border-radius: 4px;
      padding: 0px 10px;
      width: 100%;
      background: transparent;

      &:hover,
      &:focus {
        border-color: transparent;
        outline: none;
      }
    }
  }
}

.dropDownArrowLeft {
  span {
    right: auto;
    left: 3px;
    top: 14px;
    color: black;
  }
}

input::placeholder {
  font-size: small;
  font-weight: bold;
}
