/* Buttons */

.btnLightWithBg {
  font-size: 14px;
  background-color: var(--main-color);
  border: 1px solid var(--main-color);
  color: var(--white-color);
  width: fit-content;
  border-radius: 0.25rem;
}

.btnLightWithBg:hover {
  color: var(--white-color);
  background-color: var(--main-color);
}

.btnLightWithoutBg {
  font-size: 14px;
  border: 1px solid var(--main-color);
  color: var(--main-color);
  width: fit-content;
  border-radius: 0.25rem;
}

.btnLightWithoutBg:hover {
  border: 1px solid var(--main-color);
  color: var(--white-color);
  background-color: var(--main-color);
}

.btnLightWithoutBg:focus {
  border: 1px solid var(--main-color);
  color: var(--main-color);
  background-color: transparent;
}

.btnLightWithoutBg:active {
  border: 1px solid var(--main-color);
  color: var(--white-color);
  background-color: var(--main-color);
}

.btnDarkWithBg {
  font-size: 14px;
  background-color: var(--second-bg-color);
  color: var(--white-color);
  width: fit-content;
  border-radius: 0.25rem;
}

.btnDarkWithBg:hover {
  color: var(--white-color);
  background-color: var(--main-color);
}

.btnDarkWithoutBg {
  font-size: 14px;
  border: 1px solid var(--second-bg-color);
  color: var(--second-bg-color);
  width: fit-content;
  border-radius: 0.25rem;
}

.btnDarkWithoutBg:hover {
  border: 1px solid var(--second-bg-color);
  color: var(--white-color);
  background-color: var(--second-bg-color);
}

.btnDarkWithoutBg:focus {
  border: 1px solid var(--second-bg-color);
  color: var(--second-bg-color);
  background-color: transparent;
}

.btnDarkWithoutBg:active {
  border: 1px solid var(--second-bg-color);
  color: var(--white-color);
  background-color: transparent;
}

.btnSmall {
  font-size: 12px;
  padding: 0.15rem 0.5rem;
}

.btnDefault {
  font-size: 14px;
  padding: 0.25rem 1rem;
}

.btnAuth {
  font-size: 14px;
  background-color: rgb(33, 105, 225);
  border: 1px solid rgb(33, 105, 225);
  color: var(--white-color);
  width: fit-content;
  border-radius: 0.25rem;
}

.btnAuth:hover {
  color: var(--white-color);
  background-color: rgb(4, 72, 191);
}
